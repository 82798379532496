import styled, { css } from "styled-components";

export const CardItemGridWrapper = styled.li`
  --cardItemImageHeight: 256px;
  --cardItemDescription: 128px;
  --cardItemTitle: 72px;

  list-style: none;
  width: 100%;

  > a {
    margin-bottom: 0px;
  }

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;
    `}

  ${({ header }) =>
    header &&
    css`
      margin-bottom: var(--spacingL);
    `}

  @media screen and (min-width: 1128px) {
    display: list-item;
    max-width: 264px;

    ${({ header }) =>
    header &&
    css`
        margin-bottom: 0px;
      `}
  }
  scroll-margin-top: 100px;
`;

export const CardItemInfoWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacingM);
  gap: var(--spacingM);

  ${({ featured }) =>
    featured &&
    css`
      text-align: center;
      padding: var(--spacingM);
      align-items: center;
    `}

  @media screen and (max-width: 400px) {
    padding: var(--spacingS);
    gap: var(--spacingS);
  }

  @media screen and (min-width: 1128px) {
    padding: var(--spacingM);
    gap: var(--spacingM);

    ${({ featured }) =>
    featured &&
    css`
        display: grid;
        grid-template-rows: 1fr 1fr;
        align-items: center;
      `}
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  word-break: break-word;

  > h3 {
    margin: 0;
    font-size: var(--textM);
    color: var(--primary800);
    font-weight: normal;

    > a {
      text-decoration: none;
    }
  }

  ${({ imageFirst }) =>
    imageFirst &&
    css`
      > h3 {
        font-size: var(--textM);
        line-height: var(--lineHeightM);
        align-self: flex-start;
        color: var(--gray);
      }
    `}

  ${({ featured }) =>
    featured &&
    css`
      text-align: center;
      height: auto;
      display: flex;
      align-items: center;

      &:after {
        content: "";
        position: absolute;
        top: 55%;
        left: calc(50% - 28px);
        width: 56px;
        height: 1px;
        border-bottom: 1px solid var(--gray200);
      }

      > h3 {
        font-size: var(--textL);
        line-height: var(--lineHeightL);
        padding: 0px;
      }
    `}

  @media screen and (max-width: 400px) {
    > h3 {
      font-size: var(--textM);
      color: var(--primary800);
      font-weight: 400;
    }
  }

  @media screen and (min-width: 1128px) {
    height: var(--cardItemTitle);
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: var(--cardItemTitle);

    > h3 {
      padding: 0px;
      font-weight: normal;
      font-size: var(--textL);
      line-height: var(--lineHeightL);
    }

    ${({ featured }) =>
    featured &&
    css`
        height: auto;
        -webkit-line-clamp: 6;

        &:after {
          top: 50%;
        }
        > h3 {
          font-size: var(--textL);
          padding: 0px;
          height: auto;
        }
      `}

    ${({ imageFirst }) =>
    imageFirst &&
    css`
        > h3 {
          line-height: var(--lineHeightM);
          font-size: var(--textM);
          align-self: flex-start;
          color: var(--gray);
        }
      `}
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--gray);
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  min-height: 80px;
  padding: 0px;
  margin: 0px;

  ul {
    gap: 0;
  }

  > p {
    padding: 0px;
    margin: 0px;
  }

  ${({ imageFirst }) =>
    imageFirst &&
    css`
      display: flex;
      align-items: flex-end;
      justify-content: center;
    `}

  ${({ featured }) =>
    featured &&
    css`
      display: flex;
      justify-content: center;
      height: var(--cardItemDescription);

      > p {
        text-align: center;
        font-size: var(--textM);
      }
    `}

  @media screen and (min-width: 1128px) {
    display: flex;
    height: var(--cardItemDescription);
    overflow: hidden;

    > p {
      display: -webkit-box;
      line-height: var(--lineHeightS);
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    ${({ featured }) =>
    featured &&
    css`
        height: auto;
        padding: 0px;
        p {
          line-height: var(--lineHeightM);
        }
      `}
  }
`;

export const CardItemWrapper = styled.article`
  display: flex;
  list-style: none;
  width: 100%;
  background-color: var(--gray100);
  border-radius: 16px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  text-decoration: none;
  transition: transform 0.1s ease-in-out;
  text-decoration: none;

  &[href]:focus,
  &[href]:hover {
    transform: scale(1.05);
  }

  > img {
    ${({ showImageOnMobile }) =>
    showImageOnMobile ? "display: block;" : "display: none;"}
    border-radius: var(--radiusL);
    object-fit: cover;
    width: 100%;
    height: 160px;
  }

  ${({ featured }) =>
    featured &&
    css`
      justify-content: center;
      background-color: var(--secondary100);
      height: 100%;

      > img {
        display: none;
      }
    `}

  ${({ imageFirst }) =>
    imageFirst &&
    css`
      flex-direction: column-reverse;
      > img {
        width: 100%;
        height: 160px;
      }
    `}




@media screen and (min-width: 1128px) {
    flex-direction: ${({ imageFirst }) =>
    imageFirst ? "column-reverse" : "column"};

    > img {
      width: 100%;
      height: var(--cardItemImageHeight);
      display: block;
    }

    ${({ featured }) =>
    featured &&
    css`
        flex-direction: row;
        align-items: stretch;
        height: 100%;
      `}
  }
`;

export const CardButton = styled.div`
  > a {
    width: fit-content;
    height: 32px;
    font-size: var(--textS);
    color: white;
    background-color: var(--primary800);
    border-radius: var(--radiusXs);
  }
`;
